import "./date.css"

function DateInput({value, setValue, label, className}) {
    let now = new Date()
    let dateNow = now.toLocaleDateString().split("/").reverse().join("-")
    let timeNow = now.toLocaleTimeString().split(":")
    timeNow.pop()
    let timeNowTmp = timeNow.join(":")
    let date = dateNow + "T" + timeNowTmp

	return (
        <div className={"inputDate " + (className ? className : "")}>
            <label>{ label }</label>
            
            <input min={date} value={value} onChange={(e) => {
                setValue(e.target.value)
            }} type="datetime-local"/>
		</div>
	);
}

export default DateInput