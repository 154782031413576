import "./listeEvents.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import Eventreduit from "../../composants/events/eventReduit"
import BoutonSimple from "../../composants/boutons/simple"
import BoutonDanger from "../../composants/boutons/danger"
import InputTexte from "../../composants/inputs/texte"
import SelectSearch  from "react-select"
import InputDate from "../../composants/inputs/date"
import InputTexteLong from "../../composants/inputs/texteLong"
import Pagination from "../../composants/pagination/paginations"
import Modal from '@mui/material/Modal';
import axios from "axios"
import { useState, useEffect } from "react"
import { Oval } from "react-loader-spinner"

function ListeEvents() {
	const [modaleOuverte, setModaleOuverte] = useState(false);
	const [edition, setEdition] = useState(false);

	const [options, setOptions] = useState([]);
	
	const [recherche, setRecherche] = useState("");
	const [nbEventsTrouve, setNbEventsTrouve] = useState(0);
	const [listeEvents, setListeEvents] = useState([]);
	const [numeroPageActu, setNumeroPageActu] = useState(1);
	const [maxParPage, setMaxParPage] = useState(3);

	const [idEvent, setIdEvent] = useState(null);
	const [idScenario, setIdScenario] = useState(null);
	const [texte, setTexte] = useState("");
	const [message, setMessage] = useState("");
	const [dateValue, setDateValue] = useState(new Date());

	const [chargementEvent, setChargementEvent] = useState(true);
	const [chargementCreate, setChargementCreate] = useState(false);
	const [chargementUpdate, setChargementUpdate] = useState(false);
	const [chargementDelete, setChargementDelete] = useState(false);
	const [chargementEventSearch, setChargementEventSearch] = useState(true);
	const [chargementScenario, setchargementScenario] = useState(true);

	const getNiceDate = (date) => {
		date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
		return date.toISOString().slice(0,16);
	}

	const ouvrirModale = (idEvent, edition) => {
		setModaleOuverte(true)
		setIdEvent(idEvent)
		setEdition(edition)
		getAllScenarios()
		if (edition) {
			setChargementEvent(true)
			axios.post("/event/getEvent", {idEvent: idEvent}).then((res) => {
				setIdScenario({value: res.data.resultats["id_scenario"], label: res.data.resultats["titre"]})
				setTexte(res.data.resultats["event_texte"])
				setMessage(res.data.resultats["message"])
				setDateValue(getNiceDate(new Date(res.data.resultats["date_debut"])))
				setChargementEvent(false)
			}).catch(() => {setChargementEvent(false)})
		} else {
			setChargementEvent(false)
			setIdScenario(null)
			setTexte("")
			setMessage("")
			setDateValue(getNiceDate(new Date()))
		}
	}
	const fermerModale = () => {setModaleOuverte(false)}

	const checkInputs = () => {
		// TODO checker les input de event
		// date
	}

	const creerEvent = () => {
		setChargementCreate(true)
		axios.post("/event/createEvent", {
			idScenario: idScenario.value,
			texte: texte,
			message: message,
			date: new Date(dateValue).getTime()
		}).then((res) => {
			searchEvent()
		}).catch(() => {}).finally(() => {
			setChargementCreate(false)
			fermerModale()
		})
	}

	const modifierEvent = () => {
		setChargementUpdate(true)
		axios.post("/event/updateEvent", {
			idEvent: idEvent,
			idScenario: idScenario.value,
			texte: texte,
			message: message,
			date: new Date(dateValue).getTime()
		}).then((res) => {
			searchEvent()
		}).catch(() => {}).finally(() => {
			setChargementUpdate(false)
			fermerModale()
		})
	}

	const supprimerEvent = () => {
		setChargementDelete(true)
		axios.post("/event/deleteEvent", {idEvent: idEvent}).then((res) => {
			searchEvent()
		}).catch(() => {}).finally(() => {
			setChargementDelete(false)
			fermerModale()
		})
	}

	const searchEvent = () => {
		setChargementEventSearch(true)
		axios.post("/event/searchEvents", {termesRecherche: recherche, numeroPageActu: numeroPageActu-1, maxParPage: maxParPage}).then((res) => {
			setChargementEventSearch(false)
			setListeEvents(res.data.eventsTrouve)
			setNbEventsTrouve(res.data.nbEventsTrouve == 0 ? 1 : res.data.nbEventsTrouve)
		}).catch(() => {setChargementEventSearch(false)})
	}

	const getAllScenarios = () => {
		setchargementScenario(true)
		axios.get("/scenario/getAllScenarios").then((res) => {
			let optionsScenar = []
			res.data.map((scenar) => {
				optionsScenar.push({value: scenar.id, label: scenar.titre})
			})
			setchargementScenario(false)
			setOptions(optionsScenar)
		}).catch(() => {setchargementScenario(false)})
	}

	useEffect(() => {
        searchEvent()
    }, [numeroPageActu])
	
	return (
		<>
			<Header />

			<Modal open={modaleOuverte} onClose={fermerModale} >
				{/* si on est en mode edition il faut demander à l'api toute les infos grâce à l'id de l'event */}
				<div className="modaleEvent">
					<div className="row">
						<h2>{edition ? "Modifier un Événement" : "Créer un Événement"}</h2>
					</div>
					<div className="row">
						<Oval visible={chargementEvent} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
					</div>
					{
						!chargementEvent &&
						<>
							<div className="row rowHaut">
								<div className="selectSearchInput">
									<label>Scénario</label>
									{chargementScenario && <Oval visible={chargementScenario} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />}
									{!chargementScenario && <SelectSearch className="selectSearch" options={options} isSearchable={true} onChange={(value) => {setIdScenario(value)}} value={idScenario}/>}
								</div>
								<InputDate className={""} label={"Date de début"} value={dateValue} setValue={setDateValue}/>
							</div>
							<div className="row">
								<InputTexteLong className={"texteEventInput"} label={"Texte de l'Événement"} onChange={(e) => setTexte(e.target.value)} value={texte}/>
							</div>
							<div className="row">
								<InputTexteLong className={"texteEventInput"} label={"Message d'annonce"} onChange={(e) => setMessage(e.target.value)} value={message}/>
							</div>
							<div className="row">
								<Oval visible={chargementCreate || chargementDelete || chargementUpdate} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
							</div>
							<div className="row lastRow">
								<BoutonSimple action={fermerModale} texte={"Fermer"}/>
								{edition && <BoutonDanger action={supprimerEvent} texte={"Supprimer"}/>}
								<BoutonSimple action={() => {
									edition ? modifierEvent() : creerEvent()
								}} texte={edition ? "Modifier l'Événement" : "Créer l'Événement"}/>
							</div>
						</>
					}
				</div>
			</Modal>

			<main className="listeEvents">
				<div className="grandEncar">
					<div className="headerEvent">
						<InputTexte label={"Recherche"} onChange={(e) => setRecherche(e.target.value)} value={recherche} onKeyDown={(event) => {
							if (event.key === 'Enter') {
								setNumeroPageActu(1)
								searchEvent()
							}
						}}/>
						<h1>Liste Événements</h1>
						<BoutonSimple action={() => {ouvrirModale(null, false)}} texte={"Créer un Événement"} />
					</div>
					<div className="contenuEncar events">
						<Pagination onKeyDown={(event) => {event.key == "Enter" && searchEvent(); setNumeroPageActu(1)}} pageMax={Math.ceil(nbEventsTrouve/maxParPage)} maxParPage={maxParPage} setMaxParPage={setMaxParPage} numeroPageActu={numeroPageActu} setNumeroPageActu={setNumeroPageActu}>
							<Oval visible={chargementEventSearch} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
							{listeEvents.length != 0 ?
							listeEvents.map((event) => {
								return <Eventreduit key={event.id} idEvent={event.id} ouvertureModale={ouvrirModale} texte={event.event_texte} date={event.date_debut} jeu={event.jeu} lien={"/event/"+event.id} titre={event.titre} modification={true} />
							})
							:
							!chargementEventSearch && <h3>Aucun événements à venir.</h3>
							}
						</Pagination>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default ListeEvents;
