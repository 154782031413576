import "./listeScenarios.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import ScenarioReduit from "../../composants/scenarios/scenarioReduit"
import BoutonSimple from "../../composants/boutons/simple"
import BoutonDanger from "../../composants/boutons/danger"
import InputTexte from "../../composants/inputs/texte"
import InputTexteLong from "../../composants/inputs/texteLong"
import InputImage from "../../composants/inputs/image"
import Select from "../../composants/inputs/select"
import Pagination from "../../composants/pagination/paginations"
import Modal from '@mui/material/Modal';
import axios from "axios"
import FormData from 'form-data'
import { useState, useEffect } from "react"
import { Oval } from "react-loader-spinner"

function ListeScenarios() {
	const [modaleOuverte, setModaleOuverte] = useState(false);
	const [edition, setEdition] = useState(false);
	
	const [recherche, setRecherche] = useState("");
	const [nbScenariosTrouve, setNbScenariosTrouve] = useState(0);
	const [listeScenarios, setListeScenarios] = useState([]);
	const [numeroPageActu, setNumeroPageActu] = useState(1);
	const [maxParPage, setMaxParPage] = useState(3);

	const [idScenario, setIdscenario] = useState(null);
	const [titre, setTitre] = useState("");
	const [duree, setDuree] = useState(2);
	const [jeu, setJeu] = useState("");
	const [texte, setTexte] = useState("");
	const [equipe1, setEquipe1] = useState("");
	const [equipe2, setEquipe2] = useState("");
	const [regles, setRegles] = useState("");
	const [nomCarte, setNomCarte] = useState("");
	const [imageCarte, setImageCarte] = useState(null);

	const [chargementCreate, setChargementCreate] = useState(false);
	const [chargementUpdate, setChargementUpdate] = useState(false);
	const [chargementDelete, setChargementDelete] = useState(false);
	const [chargementScenario, setChargementScenario] = useState(true);
	const [chargementScenarioSearch, setChargementScenarioSearch] = useState(true);
	const [chargementImage, setchargementImage] = useState(true);

	const ouvrirModale = (idScenario, edition) => {
		setModaleOuverte(true)
		setIdscenario(idScenario)
		setEdition(edition)
		if (edition) {
			setChargementScenario(true)
			axios.post("/scenario/getScenario", {idScenar: idScenario}).then((res) => {
				setTitre(res.data.resultats["titre"])
				setDuree(res.data.resultats["duree"])
				setJeu(res.data.resultats["jeu"])
				setTexte(res.data.resultats["texte"])
				setEquipe1(res.data.resultats["vehicules_1"])
				setEquipe2(res.data.resultats["vehicules_2"])
				setRegles(res.data.resultats["regles"])
				setNomCarte(res.data.resultats["nom_carte"])
			}).catch(() => {}).finally(() => {
				setChargementScenario(false)
			})

			setchargementImage(true)
			axios.get("/images/scenarios/"+idScenario+"/carte", { responseType: 'blob' }).then((res) => {
				setImageCarte(res.data)
			}).catch((err) => {
				setImageCarte(null)
			}).finally(() => {
				setchargementImage(false)
			})
		} else {
			setChargementScenario(false)
			setchargementImage(false)
			setTitre("")
			setDuree(2)
			setJeu("wt")
			setTexte("")
			setEquipe1("")
			setEquipe2("")
			setRegles("")
			setNomCarte("")
			setImageCarte(null)
		}
	}
	const fermerModale = () => {setModaleOuverte(false)}

	const checkInputs = () => {
		// TODO checker les input de scénar
		// nom
		// duree
		// nom carte
		// image
	}

	const creerScenario = () => {
		const formData = new FormData();
		formData.append('image', imageCarte)
		formData.append('titre', titre)
		formData.append('duree', duree)
		formData.append('jeu', jeu)
		formData.append('texte', texte)
		formData.append('equipe1', equipe1)
		formData.append('equipe2', equipe2)
		formData.append('nomCarte', nomCarte)
		formData.append('regles', regles)

		setChargementCreate(true)
		axios.post("/scenario/createScenario", formData, {
			headers: {
				'Content-Type': `multipart/form-data`
			}
		}).then((res) => {
			searchScenario()
		}).catch(() => {}).finally(() => {
			setChargementCreate(false)
			fermerModale()
		})
	}

	const modifierLeScenario = () => {
		const formData = new FormData();
		formData.append('titre', titre)
		formData.append('duree', duree)
		formData.append('jeu', jeu)
		formData.append('texte', texte)
		formData.append('equipe1', equipe1)
		formData.append('equipe2', equipe2)
		formData.append('nomCarte', nomCarte)
		formData.append('regles', regles)
		formData.append('id', idScenario)
		
		if (imageCarte instanceof File) {
			console.log("on envoie la nouvelle image")
			formData.append('image', imageCarte)
			formData.append('actionImage', "ajouter")
		} else if (imageCarte instanceof Blob) {
			console.log("on fait rien avec l'image")
			formData.append('actionImage', "rien")
		} else {
			console.log("on supprime l'image")
			formData.append('actionImage', "supprimer")

		}

		setChargementUpdate(true)
		axios.post("/scenario/updateScenario", formData, {
			headers: {
				'Content-Type': `multipart/form-data`
			}
		}).then((res) => {
			searchScenario()
		}).catch(() => {}).finally(() => {
			setChargementUpdate(false)
			fermerModale()
		})
	}

	const supprimerLeScenario = () => {
		setChargementDelete(true)
		axios.post("/scenario/deleteScenario", {idScenario: idScenario}).then((res) => {
			searchScenario()
		}).catch(() => {}).finally(() => {
			setChargementDelete(false)
			fermerModale()
		})
	}

	const searchScenario = () => {
		// requete pour recupérer les scénarios
		// mettre les scénar dans le use state avec un tableau et maj la pagination
		setChargementScenarioSearch(true)
		axios.post("/scenario/searchScenarios", {termesRecherche: recherche, numeroPageActu: numeroPageActu-1, maxParPage: maxParPage}).then((res) => {
			setListeScenarios(res.data.scenariosTrouve)
			setNbScenariosTrouve(res.data.nbScenariosTrouve == 0 ? 1 : res.data.nbScenariosTrouve)
		}).catch(() => {}).finally(() => {
			setChargementScenarioSearch(false)
		})
	}

	useEffect(() => {
        searchScenario()
    }, [numeroPageActu])

	return (
		<>
			<Header />

			<Modal open={modaleOuverte} onClose={fermerModale} >
				{/* si on est en mode edition il faut demander à l'api toute les infos grâce à l'id du scénar */}
				<div className="modaleScenario">
					<div className="row">
						<h2>{edition ? "Modifier un Scénario" : "Créer un Scénario"}</h2>
					</div>
					<div className="row">
						<Oval visible={chargementScenario} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
					</div>
					{
						!chargementScenario &&
						<>
							<div className="row">
								<InputTexte className={"inputTitre"} label={"Titre"} onChange={(e) => setTitre(e.target.value)} value={titre}/>
								<InputTexte className={"inputDuree"} label={"Durée (en heure)"} onChange={(e) => setDuree(e.target.value)} value={duree}/>
								<Select label={"Jeu"} onChange={(e) => setJeu(e.target.value)} value={jeu} options={[
									{value: "wt", affichage: "War Thunder"},
									{value: "wot", affichage: "World of Tanks"},
									{value: "wows", affichage: "World of Warships"},
									{value: "enlisted", affichage: "Enlisted"},
								]} />
							</div>
							<div className="row">
								<InputTexteLong className={"texteScenarioInput"} label={"Texte du scénario"} onChange={(e) => setTexte(e.target.value)} value={texte}/>
							</div>
							<div className="row vehiculeAutor">
								<h3 className="sousTitre">Véhicules autorisés</h3>
								<div className="row">
									<div className="col">
										<InputTexteLong label={"Équipe 1"} onChange={(e) => setEquipe1(e.target.value)} value={equipe1}/>
									</div>
									<div className="col">
										<InputTexteLong label={"Équipe 2"} onChange={(e) => setEquipe2(e.target.value)} value={equipe2}/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<InputTexteLong label={"Règles"} onChange={(e) => setRegles(e.target.value)} value={regles}/>
								</div>
								<div className="col">
									<h3 className="sousTitre">Carte</h3>
									<InputTexte label={"Nom de la carte (in game)"} onChange={(e) => setNomCarte(e.target.value)} value={nomCarte}/>
									<Oval visible={chargementImage} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
									{!chargementImage && <InputImage label={"Image de la carte"} onChange={(e) => setImageCarte(e.target.files[0])} image={imageCarte} setImage={setImageCarte} />}
								</div>
							</div>
							<div className="row">
								<Oval visible={chargementCreate || chargementDelete || chargementUpdate} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
							</div>
							<div className="row lastRow">
								<BoutonSimple action={fermerModale} texte={"Fermer"}/>
								{edition && <BoutonDanger action={supprimerLeScenario} texte={"Supprimer"}/>}
								<BoutonSimple action={() => {
									edition ? modifierLeScenario() : creerScenario()
								}} texte={edition ? "Modifier le Scénario" : "Créer le Scénario"}/>
							</div>
						</>
					}
				</div>
			</Modal>

			<main className="listeScenarios">
				<div className="grandEncar">
					<div className="headerScenario">
						<InputTexte label={"Recherche"} onChange={(e) => setRecherche(e.target.value)} value={recherche} onKeyDown={(event) => {
							if (event.key === 'Enter') {
								setNumeroPageActu(1)
								searchScenario()
							}
						}}/>
						<h1>Liste Scénarios</h1>
						<BoutonSimple action={() => {ouvrirModale(null, false)}} texte={"Créer un Scénario"} />
					</div>
					<div className="contenuEncar scenarios">
						<Pagination onKeyDown={(event) => {event.key == "Enter" && searchScenario(); setNumeroPageActu(1)}} pageMax={Math.ceil(nbScenariosTrouve/maxParPage)} maxParPage={maxParPage} setMaxParPage={setMaxParPage} numeroPageActu={numeroPageActu} setNumeroPageActu={setNumeroPageActu}>
							<Oval visible={chargementScenarioSearch} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
							{listeScenarios.length != 0 ?
							listeScenarios.map((scenar) => {
								return <ScenarioReduit key={scenar.id} idScenario={scenar.id} ouvertureModale={ouvrirModale} titre={scenar.titre} texte={scenar.texte} jeu={scenar.jeu} />
							})
							:
							!chargementScenarioSearch && <h3>Aucun scenario.</h3>
							}
						</Pagination>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default ListeScenarios;
