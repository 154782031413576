import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from "axios"
import { useState, useContext } from 'react'
import Cookies from 'js-cookie';
import "./inscription.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import InputTexte from "../../composants/inputs/texte"
import BoutonSimple from "../../composants/boutons/simple"
import AuthContext from '../../contexts/authcontext';
import { Oval } from "react-loader-spinner"

function Inscription() {
	const [identifiant, setIdentifiant] = useState("")
	const [mdp, setMdp] = useState("")
	const [chaineErreur, setchaineErreur] = useState("")
	const [erreur, setErreur] = useState(false)
	const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const { isConnected, setIsConnected } = useContext(AuthContext);
	const [chargementInscription, setChargementInscription] = useState(false);
	
	const sinscrire = () => {
		let err = ""
		console.log(identifiant.length, mdp.length)
		if (identifiant.length < 2) {
			err += "Identifiant trop court (min 2 caractères)"
		}
		if (mdp.length < 6) {
			err += (err == "" ? "Mot" : " et mot") + " de passe trop court (min 2 caractères)"
		}
		if (err != "") {
			setErreur(true)
			setchaineErreur(err)
			return
		}

		setChargementInscription(true)
		axios.post("/compte/creerCompte", {"identifiant": identifiant, "mdp": mdp, "token": searchParams.get("token")}).then((res) => {
			Cookies.set("token", res.data["token"])
			setChargementInscription(false)
			setErreur(false)
			setIsConnected(true)
			navigate('/')
		}).catch((err) => {
			setChargementInscription(false)
			setchaineErreur(err.response.data)
			setErreur(true)
		})
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			sinscrire()
		}
	}
	return (
        <>
			<Header />
			<main>
				<div className='inscription'>
					<div>
						<h1>Inscription</h1>
						<div className='inputs'>
							<InputTexte onKeyDown={handleKeyPress} value={identifiant} onChange={(e) => setIdentifiant(e.target.value)} label={"Identifiant"} />
							<InputTexte type="password" onKeyDown={handleKeyPress} value={mdp} onChange={(e) => setMdp(e.target.value)} label={"Mot de passe"} />
							<BoutonSimple texte={"Valider"} action={sinscrire} />
							<Oval visible={chargementInscription} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
						</div>
						{erreur && <div className='erreur'>{chaineErreur}</div>}
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Inscription;
